<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button variant="success" class="btn-icon mr-1" @click="get">
              <feather-icon icon="RefreshCwIcon" size="16" />
            </b-button>
            <b-button
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('create_client')
              "
              variant="info"
              @click="createClient"
            >
              <feather-icon icon="PlusIcon" size="16" />
              Nuevo cliente
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(name)="data">
          <router-link :to="`/client-detail/${data.item.id}`">
            {{ data.item.full_name }}
          </router-link>
        </template>

        <template #cell(identification)="data">
          {{ identInLetter(data.item.identification_type) }}-
          {{ data.item.identification_number }}
        </template>

        <template #cell(due)="data">
          <div class="d-flex align-items-center">
            <span class="font-weight-bolder mr-1">
              {{ formatCurrency(data.item.due) }}
            </span>
            <feather-icon icon="TrendingDownIcon" class="text-danger" />
          </div>
        </template>

        <template #cell(due_addons)="data">
          <div class="d-flex align-items-center">
            <span class="font-weight-bolder mr-1">
              {{ formatCurrency(data.item.due_addons) }}
            </span>
            <feather-icon icon="TrendingDownIcon" class="text-danger" />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('edit_client')
              "
            >
              <feather-icon
                v-if="data.item.active"
                @click="activeDeactivateUser(data.item.id, false)"
                icon="UserXIcon"
                size="16"
                class="text-danger cursor-pointer"
              />

              <feather-icon
                v-else
                @click="activeDeactivateUser(data.item.id, true)"
                icon="UserCheckIcon"
                size="16"
                class="text-success cursor-pointer"
              />
            </span>
            <feather-icon
              @click="editClient(data.item)"
              icon="EditIcon"
              size="16"
              class="mx-2 text-success cursor-pointer"
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('edit_client')
              "
            />
            <feather-icon
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('delete_client')
              "
              @click="deleteClient(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="text-danger cursor-pointer"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted" v-if="totalRegisters > 0"
              >Mostrando {{ fromRegister }} a {{ toRegister }} de
              {{ totalRegisters }} registros
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="items.length !== totalRegisters"
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <!-- Right sidebar -->
      <b-sidebar
        v-model="showClientModal"
        id="sidebar-right"
        :title="(flagUpdateAction ? 'Actualizar ' : 'Nuevo ') + 'Cliente'"
        right
        shadow
        width="30%"
      >
        <div class="p-2">
          <!-- form -->
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(storeClient)">
              <div class="row">
                <div class="col">
                  <!-- name -->
                  <b-form-group label="Nombre" label-for="name">
                    <ValidationProvider
                      name="Nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="name"
                        v-model="client.name"
                        name="name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col">
                  <!-- surname -->
                  <b-form-group label="Apellido" label-for="surname">
                    <b-form-input id="surname" v-model="client.surname" />
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <!-- email -->
                  <b-form-group label="Email" label-for="email">
                    <ValidationProvider
                      name="Correo"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="email"
                        v-model="client.email"
                        name="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group
                    label="Tipo de identificación"
                    label-for="address"
                  >
                    <ValidationProvider
                      name="Tipo de identificación"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-select
                        v-model="client.identification_type"
                        :options="identificationTypes"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <!-- identification_number -->
                  <b-form-group
                    label="Número de identificacón"
                    label-for="identification_number"
                  >
                    <ValidationProvider
                      name="Número de identificación"
                      rules="required|integer"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="identification_number"
                        v-model="client.identification_number"
                        name="identification_number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group label="Provincia" label-for="province">
                    <ValidationProvider
                      name="Provincia"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-select
                        @change="getCantonsC()"
                        value-key="id"
                        v-model="client.province"
                        name="province"
                      >
                        <b-form-select-option
                          v-for="province in provinces"
                          :value="province"
                          :key="province.id"
                        >
                          {{ province.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group label="Canton" label-for="canton">
                    <ValidationProvider
                      name="canton"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-select
                        @change="getDistrictsC()"
                        value-key="id"
                        v-model="client.canton"
                        name="canton"
                      >
                        <b-form-select-option
                          v-for="c in cantons"
                          :value="c"
                          :key="c.id"
                        >
                          {{ c.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group label="Distrito" label-for="district">
                    <ValidationProvider
                      name="Distrito"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-select
                        @change="getBarriosC()"
                        value-key="id"
                        v-model="client.district"
                        name="district"
                      >
                        <b-form-select-option
                          v-for="d in districts"
                          :value="d"
                          :key="d.id"
                        >
                          {{ d.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group label="Barrio" label-for="barrio">
                    <ValidationProvider
                      name="barrio"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-select
                        value-key="id"
                        v-model="client.barrio"
                        name="barrio"
                      >
                        <b-form-select-option
                          v-for="b in barrios"
                          :value="b"
                          :key="b.id"
                        >
                          {{ b.name }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col">
                  <!-- address -->
                  <b-form-group label="Dirección" label-for="address">
                    <ValidationProvider
                      name="Dirección"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="address"
                        v-model="client.address"
                        name="address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <b-form-group label="Teléfono" label-for="phone">
                    <ValidationProvider
                      name="Teléfono"
                      rules="required|integer"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="phone"
                        v-model="client.phone"
                        name="phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col-6 pt-2">
                  <b-form-checkbox
                    id="message_notify"
                    v-model="client.message_notify"
                    name="message_notify"
                  >
                    Enviar notificaciones vía SMS
                  </b-form-checkbox>
                </div>
              </div>

              <!-- submit button -->
              <div class="text-center">
                <b-overlay
                  :show="loading_btn"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    :disabled="loading_btn"
                    type="submit"
                    variant="primary"
                  >
                    <feather-icon icon="SaveIcon" size="16" />
                    Guardar
                  </b-button>
                </b-overlay>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-sidebar>
    </b-card>
  </b-overlay>
</template>

<script>
//Vuex
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useActions, useState } = createNamespacedHelpers("app"); // specific module name

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BSidebar,
  BOverlay,
  BFormCheckbox
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onMounted, ref, watch, computed } from "@vue/composition-api";
import useInvoicesList from "./apps/invoice/invoice-list/useInvoiceList";
import { useToast } from "vue-toastification/composition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//Validation forms
import { ValidationProvider, ValidationObserver } from "vee-validate";

//Services
import clientService from "@/services/client.service";
import userService from "@/services/user.service";
import store from "@/store";
import { mapState } from "vuex";

//Helpers
import {
  confirmAlert,
  identificationInLetter,
  perPageBaseOptions,
  perPageBaseRegisters,
  currencyFormat
} from "@/helpers";

//import useVuelidate from '@vuelidate/core'
//import { required, email } from '@vuelidate/validators'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    BFormGroup,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormCheckbox
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  setup() {
    const toast = useToast();

    //Vuex
    const { getProvinces, getCantons, getDistricts, getBarrios } = useActions([
      "getProvinces",
      "getCantons",
      "getDistricts",
      "getBarrios"
    ]);

    const { provinces, cantons, districts, barrios } = useState([
      "provinces",
      "cantons",
      "districts",
      "barrios"
    ]);

    //Data (properties)
    const loading = ref(true);
    const items = ref([]);
    const tableColumns = ref([
      { key: "name", label: "Nombres", sortable: true },
      { key: "identification", label: "Identificación", sortable: true },
      { key: "email", label: "Correo", sortable: true },
      { key: "phone", label: "Teléfono", sortable: true },
      { key: "due", label: "Mensajeria", sortable: true },
      { key: "due_addons", label: "Compras", sortable: true },
      { key: "actions", label: "Acciones" }
    ]);

    const showClientModal = ref(false);
    const flagUpdateAction = ref(false);

    const identificationTypes = ref([
      { value: "01", text: "Cédula fisica" },
      { value: "02", text: "Cédula juridica" },
      { value: "03", text: "DIMEX" },
      { value: "04", text: "NITE" }
    ]);

    const baseClient = {
      name: "",
      surname: "",
      email: "",
      password: "",
      identification_type: "01",
      identification_number: "",
      address: "",
      phone: "",
      province: null,
      canton: null,
      district: null,
      barrio: null
    };

    const client = ref(JSON.parse(JSON.stringify(baseClient)));

    //Pagination
    const currentPage = ref(1);
    const lastPage = ref(1);
    const totalRegisters = ref(1);
    //const perPage = ref(10)
    const search = ref("");
    const fromRegister = ref(1);
    const toRegister = ref(perPageBaseRegisters);
    const perPage = ref(perPageBaseRegisters);
    const perPageOptions = ref(perPageBaseOptions);
    const loading_btn = ref(false);

    //Methods
    const formatCurrency = amount => {
      return currencyFormat(amount);
    };

    const getProvincesC = (preselect = false) => {
      getProvinces({}).then(res => {
        if (!preselect) {
          client.value.province = res[0];
        }
        getCantonsC(preselect);
      });
    };

    const getCantonsC = (preselect = false) => {
      getCantons({ province: client.value.province.number }).then(res => {
        if (!preselect) {
          client.value.canton = res[0];
        }
        getDistrictsC(preselect);
      });
    };

    const getDistrictsC = (preselect = false) => {
      const province = client.value.province.number;
      const canton = client.value.canton.number;
      getDistricts({ province: province, canton: canton }).then(res => {
        if (!preselect) {
          client.value.district = res[0];
        }
        getBarriosC(preselect);
      });
    };

    const getBarriosC = (preselect = false) => {
      const province = client.value.province.number;
      const canton = client.value.canton.number;
      const district = client.value.district.number;
      getBarrios({
        province: province,
        canton: canton,
        district: district
      }).then(res => {
        if (!preselect) {
          client.value.barrio = res[0];
        }
      });
    };

    const identInLetter = identification_type => {
      return identificationInLetter(identification_type);
    };

    const createClient = () => {
      client.value = JSON.parse(JSON.stringify(baseClient));
      showClientModal.value = true;
      flagUpdateAction.value = false;
    };
    const editClient = item => {
      client.value = JSON.parse(JSON.stringify(item));
      getProvincesC(true);
      showClientModal.value = true;
      flagUpdateAction.value = true;
    };

    const activeDeactivateUser = async (user_id, active) => {
      try {
        const confirm = await confirmAlert(
          active ? "Activar cliente" : "Desactivar cliente",
          active
            ? "Vas a activar el cliente deseas continuar"
            : "Vas a desactivar el cliente deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await userService.activeDeactivateUser({
          id: user_id
        });

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: "CheckIcon",
              variant: "warning"
            }
          });
          return;
        }

        const index = items.value.findIndex(i => i.id === res.data.id);
        items.value[index].active = res.data.active;
        items.value = JSON.parse(JSON.stringify(items.value));
        store.dispatch("app/getGeneralData");
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
    };

    const deleteClient = async client_id => {
      try {
        const confirm = await confirmAlert(
          "Eliminar Cliente",
          "Vas a eliminar el cliente deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await clientService.deleteClient({
          id: client_id
        });

        const index = items.value.findIndex(i => i.id === client_id);
        items.value.splice(index, 1);
        store.dispatch("app/getGeneralData");
        //items.value = JSON.parse(JSON.stringify(items.value))
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    const storeClient = async () => {
      try {
        loading_btn.value = true;
        const { data: res } = await (flagUpdateAction.value
          ? clientService.updateClient(client.value)
          : clientService.storeClient(client.value));
        loading_btn.value = false;

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: "CheckIcon",
              variant: "warning"
            }
          });
          return;
        }

        showClientModal.value = false;
        //If store
        if (!flagUpdateAction.value) items.value.unshift(res.data);
        //If update
        if (flagUpdateAction.value) {
          const index = items.value.findIndex(i => i.id === res.data.id);
          items.value[index] = res.data;
          items.value = JSON.parse(JSON.stringify(items.value));
        }
        store.dispatch("app/getGeneralData");
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
      loading_btn.value = false;
    };

    const get = async (page = 1, searchQuery = {}) => {
      loading.value = true;
      searchQuery.perPage = perPage.value;
      const { data: res } = await clientService.getClients(page, searchQuery);
      items.value = res.data.data;
      loading.value = false;

      //Pagination
      //perPage.value = res.data.per_page
      //  currentPage.value = res.current_page
      lastPage.value = res.data.last_page;
      toRegister.value = res.data.to;
      fromRegister.value = res.data.from;

      totalRegisters.value = res.data.total;
    };

    //Wachers
    watch(currentPage, (currentPage, prevCurrentPage) => {
      get(currentPage, {
        term: search.value
      });
    });

    watch(perPage, currentPerPage => {
      toRegister.value = currentPerPage;
      get(1, {
        term: search.value
      });
    });

    watch(search, async (search, prevSearch) => {
      if (currentPage.value === 1) {
        await get(currentPage.value, {
          term: search
        });
      } else {
        currentPage.value = 1;
      }
    });

    //Mounted
    onMounted(() => {
      get();
      getProvincesC();
    });

    const {
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant
    } = useInvoicesList();

    return {
      formatCurrency,
      tableColumns,
      perPage,
      currentPage,
      totalRegisters,
      perPageOptions,
      search,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      items,
      fromRegister,
      toRegister,
      client,
      storeClient,
      identificationTypes,
      showClientModal,
      editClient,
      createClient,
      deleteClient,
      identInLetter,
      flagUpdateAction,
      activeDeactivateUser,
      provinces,
      cantons,
      districts,
      barrios,
      getProvincesC,
      getCantonsC,
      getDistrictsC,
      getBarriosC,
      loading_btn,
      loading,
      get
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
